<template>
    <div>
        <h1>Tolerance!!</h1>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hasSetupListeners: false
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        }
    },
    beforeMount() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('portfoliotolerance', this.onPortfolioTolerance);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('portfoliotolerance', this.onPortfolioTolerance);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'PortfolioTolerance',
                args: {}
            });
        },
        onPortfolioTolerance(event) {
            console.log(event);
        }
    }
}
</script>